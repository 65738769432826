<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ENROL</span> STUDENT
    </PageHeader>

    <validation-observer ref="observer">
      <v-form @submit.prevent ref="form1">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title primary-title>
                Student and Academic Calendar
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Index Number"
                      ref="indexNumber"
                      rules="required"
                    >
                      <v-autocomplete
                        class="rounded-0"
                        @change="studentDetails"
                        :loading="loadStudent"
                        v-model="index_number"
                        :items="studentcampusBasket"
                        :search-input.sync="searchStudent"
                        @keyup="debouncesearchStudent"
                        :error-messages="errors"
                        hide-details="auto"
                        hide-selected
                        item-text="index_number_title_name"
                        item-value="id"
                        label="Index Number"
                        outlined
                      >
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-avatar>
                              <v-img
                                :src="data.item.avatar_url"
                                :lazy-src="data.item.avatar_url"
                              >
                              </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.index_number_title_name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title v-if="loadStudent">
                              <span class="mr-4">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular
                              ></span>
                              Searching
                              <strong>Student...</strong>
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              No
                              <strong>Student found...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Calendar"
                      rules="required"
                    >
                      <v-select
                        :error-messages="errors"
                        class="rounded-0"
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="iscalendarLoaded"
                        v-model="calendar"
                        :items="basket_calendar"
                        item-text="academic_calendar"
                        item-value="id"
                        outlined
                        label="Calendar"
                        @click="fetchCalendar"
                        hide-details="auto"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Calendar(s)...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Semester"
                      rules="required"
                    >
                      <v-select
                        class="rounded-0"
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="issemesterLoaded"
                        @click="fetchSemester"
                        v-model="semester"
                        :items="basket_semester"
                        item-value="id"
                        item-text="semester"
                        outlined
                        label="Semester"
                        hide-details="auto"
                        :error-messages="errors"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Semesters...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expand-transition>
              <v-card v-if="showpaymentDetails">
                <v-card-title
                  class="primary"
                  primary-title
                  v-if="getters_checkstudent_balance"
                >
                  <span
                    class="error--text"
                    v-if="getters_checkstudent_balance.balance > 0"
                  >
                    {{
                      `THE STUDENT IS OWING GHS ${parseFloat(
                        getters_checkstudent_balance.balance
                      ).toFixed(2)}`
                    }}
                  </span>
                  <span v-else class="success--text">
                    {{
                      `GHS ${
                        getters_checkstudent_balance.length === 0
                          ? "0.00"
                          : parseFloat(
                              getters_checkstudent_balance.balance
                            ).toFixed(2)
                      } `
                    }}
                  </span>
                </v-card-title>
                <v-card-title primary-title> Payment Details </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Payment Description"
                        rules="required|alpha"
                      >
                        <v-text-field
                          outlined
                          hide-details="auto"
                          class="rounded-0"
                          label="Payment Description"
                          :error-messages="errors"
                          v-model="description"
                          disabled
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount Paid"
                        rules="required|numeric|confirmed:confirmation"
                      >
                        <v-text-field
                          prefix="GHS"
                          :error-messages="errors"
                          outlined
                          type="number"
                          class="rounded-0"
                          label="Amount Paid"
                          v-model.number="amountPaid"
                          v-model.lazy="amountPaid"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount Paid"
                        vid="confirmation"
                      >
                        <v-text-field
                          prefix="GHS"
                          :error-messages="errors"
                          outlined
                          type="number"
                          class="rounded-0"
                          label="Re-enter Amount Paid"
                          v-model.number="confirmation"
                          v-model.lazy="confirmation"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Payment Method"
                        rules="required"
                      >
                        <v-text-field
                          class="rounded-0"
                          hide-details="auto"
                          outlined
                          disabled
                          label="Payment Method"
                          v-model="paymentMethod"
                          v-model.lazy="paymentMethod"
                          min="1"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Receipt Number"
                        rules="required|alpha_num"
                      >
                        <v-text-field
                          hide-details="auto"
                          outlined
                          class="rounded-0"
                          label="Receipt Number"
                          v-model.number="receiptNumber"
                          v-model.lazy="receiptNumber"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-footer color="transparent">
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="
                      !getters_abilities.includes('save_enrol_student_access')
                    "
                    color="primary"
                    large
                    width="200"
                    @click="enrolstudentBtn"
                  >
                    Enrol Student
                  </v-btn>
                </v-footer>
              </v-card>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    alpha,
    numeric,
    alpha_num,
    confirmed,
  } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} cannot be empty",
  });

  extend("alpha_num", {
    ...alpha_num,
    message: "{_field_} should contain a mixture of alphabets and numbers",
  });

  extend("alpha", {
    ...alpha,
    message: "{_field_} should contain a mixture of alphabets",
  });

  extend("numeric", {
    ...numeric,
    message: "{_field_} should be numeric",
  });

  extend("confirmed", {
    ...confirmed,
    message: "Re-enter same amount in the next field",
  });
  export default {
    components: { PageHeader, Response, ValidationObserver, ValidationProvider },
    setup() {
      const vm = getCurrentInstance()
      const {
        /* getLevel, */
        getSemester,
        getCurCalendar,
        getStudentCampus,
        enrolStudent,
        getStudentDetails,
        signOut,
        getCheckStudentBalance,
      } = useActions([
        /* "getLevel", */
        "getSemester",
        "getCurCalendar",
        "getStudentCampus",
        "enrolStudent",
        "getStudentDetails",
        "signOut",
        "getCheckStudentBalance",
        "getters_abilities",
      ]);

      const {
        getters_checkstudent_balance,
        getters_enrol_student,
        getters_semester,
        getters_curcalendar,
        getters_student_campus,
        user,
        getters_student_details,
        getters_abilities,
      } = useGetters([
        "getters_checkstudent_balance",
        "getters_enrol_student",
        "getters_semester",
        "getters_curcalendar",
        "getters_student_campus",
        "getters_student_details",
        "user",
        "getters_abilities",
      ]);

      const enrolHeader = reactive({
        studentStatus: null,
        searchStudent: null,
        response: false,
        iscalendarLoaded: false,
        issemesterLoaded: false,
        islevelLoaded: false,
        loadStudent: false,
        calendar: "",
        semester: "",
        level: "",
        index_number: null,
        basket_calendar: [],
        basket_semester: [],
        basket_level: [],
        studentcampusBasket: [],
        absolute: true,
        overlay: false,
        amountPaid: null,
        confirmation: null,
        paymentMethod: "Bank Receipt",
        receiptNumber: null,
        showpaymentDetails: false,
        description: "Fees",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: null,
        observer: null,
        form1: null,
        indexNumber: null,
      });

      const {
        indexNumber,
        observer,
        form1,
        studentStatus,
        searchStudent,
        response,
        overlay,
        basket_calendar,
        basket_semester,
        /* basket_level, */
        iscalendarLoaded,
        issemesterLoaded,
        /* islevelLoaded, */
        loadStudent,
        studentcampusBasket,
        calendar,
        //level,
        semester,
        amountPaid,
        paymentMethod,
        receiptNumber,
        showpaymentDetails,
        index_number,
        description,
        msgHeader,
        msgBody,
        msgIcon,
        color,
      } = toRefs(enrolHeader);

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_curcalendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_curcalendar.value;
          return;
        }
        getCurCalendar().then(() => {
          basket_calendar.value = getters_curcalendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const studentDetails = (index_number) => {
        if (index_number) {
          loadStudent.value = true;

          getStudentDetails(index_number).then(() => {
            loadStudent.value = false;
            if (
              getters_student_details.value.student_status.status.toLowerCase() !==
              "in school"
            ) {
              studentStatus.value =
                "Status: " + getters_student_details.value.student_status.status;
              indexNumber.value.setErrors(studentStatus.value);
              return;
            }
            studentStatus.value = "";
          });
          getCheckStudentBalance(index_number);
        }
      };

      watch(
        () => [
          index_number.value,
          calendar.value,
          semester.value,
          showpaymentDetails.value,
          studentStatus.value,
        ],
        () => {
          if (
            studentStatus.value == "" &&
            index_number.value &&
            calendar.value &&
            semester.value
          ) {
            showpaymentDetails.value = true;
          } else {
            showpaymentDetails.value = false;
          }
        }
      );

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;
          const infoBasket = {
            campus: user.value.campus,
          };

          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => (loadStudent.value = false));
        }
      });

      const enrolstudentBtn = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            overlay.value = true;

            response.value = false;
            let basket = {
              calendar: calendar.value,
              //level: level.value,
              semester: semester.value,
              program: getters_student_details.value.program_id,
              campus: getters_student_details.value.campus_id,
              session: getters_student_details.value.session_id,
              index_number: getters_student_details.value.index_number,
              amount_paid: amountPaid.value,
              payment_method: paymentMethod.value,
              receipt_number: receiptNumber.value,
              staff_id: user.value.id,
              description: description.value,
            };

            enrolStudent(basket)
              .then(() => {
                overlay.value = false;
                msgHeader.value = "Success";
                msgBody.value = "Saved successfully";
                msgIcon.value = "mdi-check-circle";
                color.value = "success";
                form1.value.reset();
              })
              .catch((e) => {
                form1.value.reset();
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                overlay.value = false;
                color.value = "error";

                switch (e.response.status) {
                  case 403:
                    msgBody.value = e.response.data.message;
                    break;
                  case 422:
                    msgBody.value = e.response.data.message;
                    break;
                  case 423:
                    msgBody.value = e.response.data.message;
                    signOut().then(() => {
                      vm.proxy.$router.replace({
                        name: "Login",
                      });
                    });
                    break;
                  default:
                    msgBody.value =
                      "Try Again Later Or Call The System Administrator";
                    break;
                }
              })
              .finally(() => {
                overlay.value = false;
                response.value = true;
                description.value = "Fees";
                paymentMethod.value = "Bank Receipt";
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(enrolHeader),
        fetchCalendar,
        /* fetchLevel, */
        fetchSemester,
        enrolstudentBtn,
        studentDetails,
        studentcampusBasket,
        getters_enrol_student,
        debouncesearchStudent,
        getters_abilities,
        getters_checkstudent_balance,
      };
    },
  };
</script>
